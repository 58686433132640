import(/* webpackMode: "eager" */ "/molar/frontend/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"display\":\"swap\",\"fallback\":[\"system-ui\",\"arial\",\"sans\"],\"style\":[\"italic\",\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-work-sans\",\"weight\":\"variable\"}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager" */ "/molar/frontend/node_modules/.pnpm/next@14.2.26_@babel+core@7.26.0_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.82.0/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"fallback\":[\"serif\"],\"src\":\"../../public/fonts/feijoa-medium.woff2\",\"variable\":\"--font-feijoa-medium\"}],\"variableName\":\"feijoaMedium\"}");
;
import(/* webpackMode: "eager" */ "/molar/frontend/node_modules/.pnpm/tailwindcss@3.4.4_ts-node@10.9.2_@types+node@20.10.5_typescript@5.3.3_/node_modules/tailwindcss/tailwind.css");
;
import(/* webpackMode: "eager", webpackExports: ["ActiveCampaignTracking"] */ "/molar/frontend/src/common/components/ActiveCampaignTracking/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OpenReplayTrackingLoader"] */ "/molar/frontend/src/common/components/OpenReplayTrackingLoader/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatProvider"] */ "/molar/frontend/src/common/contexts/ChatContext.tsx");
;
import(/* webpackMode: "eager" */ "/molar/frontend/src/styles/globals.scss");
